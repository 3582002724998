<template>
  <v-row class="cw-alert" no-gutters>
    <v-col cols="12">
      <v-alert
        v-bind="$attrs"
        class="mb-0"
      >
        <slot/>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CwAlert',

  inheritAttrs: false,
};
</script>

<style lang="scss">
.cw-alert + .cw-alert {
  .col {
    padding-top: 0;
  }
}
</style>
